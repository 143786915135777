import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Link from '../components/Link';

import * as classes from '../custom-styles/PricingPage.module.css'
import ContactPage from '../components/ContactPage';
import TransparentBackground from '../components/backgrounds/TransparentBackground';
import styled from 'styled-components';
import Checkmark from '../images/CheckMark_Price.svg'
import { navigate } from 'gatsby';

const MainContent = styled(TransparentBackground)`
  background-size: cover;
  height: 240px;
  width: 100%;
`;

function PricingPage() {

  return (<>

    <MainContent>
      <div className={`${classes.chooseoption} py-4`}>
        <h1 className={`${classes.chooseprice}`}>Choose Your Plan</h1>
        <div className={`${classes.servicesborder}`}></div>
        <p>Simple Pricing. No Hidden Fees. Advanced Features</p>
      </div>
    </MainContent>
    <div className={`${classes.pricingcardmainbg}`}>
      <Container className='py-5'>
        <Row>
          <Col lg={6}>
            <FreePlanComponent/>
          </Col>
          <Col lg={6}>
            <PremiumPlanComponent/>
          </Col>
        </Row>
      </Container>
    </div>
    <ContactPage />

  </>)
}

const FreePlanComponent = () => {

  return (
    <div className=''>
      <div className={`${classes.pricingcard} position-relative`}>
        <div className=''>
          <h4>Free</h4>
        </div>
        <div className={`${classes.pricingborder}`}></div>
        <div className='py-4'>
          <h5>
            <span className={`${classes.pricepoint}`}>$0</span>
            <br/><span className={`${classes.pricefrequency}`}>/&nbsp;application instance</span>
            <br/><span className={`${classes.pricefrequency}`}>/&nbsp;month</span>
          </h5>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} alt=''  />
            </div>
            <div className=''>
              <h6 className='m-0'>One data application location.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} alt=''  />
            </div>
            <div className=''>
              <h6 className='m-0'>Run in your private AWS, Azure, Google Cloud or On-prem infrastructure.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} alt=''  />
            </div>
            <div className=''>
              <h6 className='m-0'>One data application of each type.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} alt=''  />
            </div>
            <div className=''>
              <h6 className='m-0'>Single user account.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} alt=''  />
            </div>
            <div className=''>
              <h6 className='m-0'>Provision unlimited data services.</h6>
            </div>
          </div>
        </div>
        <div className={`${classes.pricingbuttonon} text-center`}>
          <a href='/console/signup' onClick={() => navigate('/console/signup')} className={`${classes.pricingsignup}`}>Sign Up</a>
        </div>
      </div>
    </div>
  );
}

const PremiumPlanComponent = () => {

  return (

    <div className=''>
      <div className={`${classes.pricingcard} position-relative`}>
        <div className=''>
          <h4>Premium</h4>
        </div>
        <div className={`${classes.pricingborder} position-relative`}></div>
        <div className='py-4'>
          <h5>
            <span className={`${classes.pricepoint}`}>$49.99</span>
            <br/><span className={`${classes.pricefrequency}`}>/&nbsp;application instance</span>
            <br/><span className={`${classes.pricefrequency}`}>/&nbsp;month</span>
          </h5>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>All free plan features.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Unlimited data application locations.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Unlimited managed provisioned data services.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Organize your data services into projects.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Centralized monitoring for all your data services.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Unlimited user accounts.</h6>
            </div>
          </div>
          <div className='d-flex align-items-center py-2'>
            <div className='d-flex'>
              <StaticImage className={`${classes.w20} mx-2`} src={'../images/checkmark.png'} />
            </div>
            <div className=''>
              <h6 className='m-0'>Fine grained access control to your data services.</h6>
            </div>
          </div>
        </div>
        <div className={`${classes.pricingbuttonon} text-center`} >
          <a href='/console/signup' onClick={() => navigate('/console/signup')} className={`${classes.pricingsignup}`}>Sign Up</a>
        </div>
      </div>
    </div>
  );
}

export default PricingPage;
