// extracted by mini-css-extract-plugin
export var chooseoption = "PricingPage-module--chooseoption--3t44P";
export var chooseprice = "PricingPage-module--chooseprice--0g0lz";
export var cont_btn1 = "PricingPage-module--cont_btn1--CS+Ms";
export var cont_btn2 = "PricingPage-module--cont_btn2--7V4hr";
export var pricefrequency = "PricingPage-module--pricefrequency--AeKam";
export var pricepoint = "PricingPage-module--pricepoint--18GIm";
export var pricingborder = "PricingPage-module--pricingborder--fnf+7";
export var pricingbuttonon = "PricingPage-module--pricingbuttonon--BvW-Y";
export var pricingcard = "PricingPage-module--pricingcard--RZ9cQ";
export var pricingcardmainbg = "PricingPage-module--pricingcardmainbg--R5v3n";
export var pricingsignup = "PricingPage-module--pricingsignup--tb2dO";
export var servicesborder = "PricingPage-module--servicesborder--ygMn+";
export var w20 = "PricingPage-module--w20--Np4Vy";